import React from 'react';

const Validation = (props) => {

  return (
    <>
      {
        props.formik.touched[props.control] && props.formik.errors[props.control] ?
          <div className="text-danger d-block">
            <small>{props.formik.errors[props.control]}</small>
          </div>
          : null
      }
    </>
  )
}

export default Validation;
