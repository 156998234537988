
const AddressReducer = (state = [], action) => {

  switch (action.type) {
    case 'SET_ADDRESS_ITEM':
      return action.payload;

    default:
      return state;
  }
}

export default AddressReducer;
