
const EntrepreneurReducer = (state = JSON.parse(localStorage.getItem('entrepreneur')), action) => {

  switch (action.type) {
    case 'SET_ENTREPRENEUR':
      return action.payload;
    default:
      return state;
  }
}

export default EntrepreneurReducer;
