import api from "./interceptor";

export function processOrder(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/order", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function newOrder() {
  return new Promise((resolve, reject) => {
    api
      .get("/orders/unseen")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function orderSeen(orderId) {
  return new Promise((resolve, reject) => {
    api
      .post("/orders/change-unseen", { order_id: orderId })
      .then((resp) => resolve(resp.data))
      .catch((error) => reject(error));
  });
}

export function listOrder(orderType) {
  return new Promise((resolve, reject) => {
    api
      .get("/store-order/" + orderType)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function productByOrder(orderId) {
  return new Promise((resolve, reject) => {
    api
      .get("/order-products/" + orderId)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function changeOrderStatus(id, $data) {
  return new Promise((resolve, reject) => {
    api
      .put("/change-status/" + id, $data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function changeIndividualOrderStatus(id, $data, value) {
  return new Promise((resolve, reject) => {
    api
      .put("/order-product/change-status/" + id, $data, value)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function generateBill(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/order-product-details", { order_product_id: data })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function cancelOrder(orderId) {
  // const data = {
  //     product_id: productId,
  // };
  // return new Promise((resolve, reject) => {
  //     axios.put(apiUrl + '/cart', data, headers)
  //         .then(
  //             response => resolve(response.data)
  //         )
  //         .catch(error => reject(error));
  // });
}
