import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import * as AuthApi from '../../../services/authApi'
import Validation from 'src/helpers/validation'
import cogoToast from 'cogo-toast'

const ResetCode = props => {

  const formik = useFormik({
    initialValues: {
      reset_token: '',
    },
    // validate: validateForm,
    validationSchema:
      Yup.object({
        reset_token: Yup.string().required("Reset Code is required."),
      }),
    onSubmit: values => {
      values.email = props.email;
      AuthApi.verifyResetCode(values).then(
        (res) => {
          if (res.statusCode === 200) {
            props.applyCode(values.reset_token);
            cogoToast.success('Reset code is valid!');
          } else {
            formik.setFieldError("reset_token", "Invalid Reset Code!");
          }
        })
        .catch(error =>
          formik.setFieldError("reset_token", "Invalid Reset Address!")
        );
    }
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit} method="post" className="login-form" encType="multipart/form-data" >
                    <h1>Reset Code</h1>
                    <p className="text-muted">Please enter reset code sent to your email.</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" value={formik.values.reset_token} name="reset_token"
                        onChange={formik.handleChange} placeholder="Reset Code" autoComplete="username" />
                    </CInputGroup>
                    <Validation className="mt-0" formik={formik} control="reset_token" />
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" type="submit" className="px-4">Reset</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CLink to="/login">
                          <CButton color="link" className="px-0">Back to login!</CButton>
                        </CLink>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Welcome to the market place.</p>
                    <p>Don't have an account yet?</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Become An Entrepreneur!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetCode
