import api from './interceptor';

export function getAddress() {
  return new Promise((resolve, reject) => {
    api.get('/get-address')
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}
