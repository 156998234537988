import api from './interceptor';

export function getCategory() {
  return new Promise((resolve, reject) => {
    api.get('/parent-category')
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}
