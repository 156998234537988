import * as CategoryApi from '../../services/categoryApi';

export const setCategoryItem = () => {
    return (dispatch) => {
        CategoryApi.getCategory().then(
            catData => {
                if (catData.statusCode === 200) {
                    dispatch({ type: 'SET_CATEGORY_ITEM', payload: catData.data })
                }
            }
        )
    }
}