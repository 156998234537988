import axios from 'axios';
import api from './interceptor';


const apiUrl = process.env.REACT_APP_API_URL;

export function login(data) {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/entrepreneur-login', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function forgotPassword(data) {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/forgotPassword', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function verifyResetCode(data) {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/reset-code', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function resetPassword(data) {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/resetPassword', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function register(data) {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/entrepreneur', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function getEntrepreneurDetail() {
  return new Promise((resolve, reject) => {
    api.get('/entrepreneur-data')
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  })
}


export function verifyAccount(data){
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/validate-otp', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}

export function resendOTP(data){
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/resend-otp', data)
      .then(
        response => resolve(response.data)
      )
      .catch(error => reject(error));
  });
}