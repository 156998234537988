import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as AuthApi from "../../../services/authApi";
import Validation from "src/helpers/validation";
import cogoToast from "cogo-toast";

const ResetCode = ({ seller }) => {
  const [cooldown, setCooldown] = useState(0);
  const [resendReady, setResendReady] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let intervalId;
    if (cooldown > 0) {
      setResendReady(false);
      intervalId = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
    } else {
      setResendReady(true);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [cooldown]);

  const formik = useFormik({
    initialValues: {
      verification_code: "",
    },
    validationSchema: Yup.object({
      verification_code: Yup.string()
        .matches(/^\d{4}$/, "Verification code must have 4 digits")
        .required("Verification code is required"),
    }),
    onSubmit: (values) => {
      setSubmitted(true);
      values.otp = values.verification_code;
      values.user_id = seller.user_id;
      AuthApi.verifyAccount(values)
        .then((res) => {
          if (res.statusCode === 200) {
            cogoToast.success("Phone number verified successfully!");
            history.push("/login");
          } else {
            formik.setFieldError("verification_code", "Invalid Reset Code!");
          }
        })
        .catch((error) => {
          setSubmitted(false);
          let message = error?.response?.data.message;
          formik.setFieldError("verification_code", message ? message : 'Something went wrong!');
        });
    },
  });

  const sendVerificationCode = () => {
    if (resendReady) {
      AuthApi.resendOTP({user_id:seller.user_id})
        .then((res) => {
          if (res.statusCode === 200) {
            cogoToast.success("OTP Send Successfully");
            setCooldown(61);
            setResendReady(false);
          } else {
            cogoToast.error("Something Went Wrong!");
          }
        })
        .catch((error) => {
          let message = error?.response?.data.message;
          cogoToast.error(message);
        });
    }
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    onSubmit={formik.handleSubmit}
                    method="post"
                    className="login-form"
                    encType="multipart/form-data"
                  >
                    <h1>Verify Account</h1>
                    <p className="text-muted">
                      Please enter verification code sent to your phone number.
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        value={formik.values.verification_code}
                        name="verification_code"
                        onChange={formik.handleChange}
                        placeholder="Verification Code"
                      />
                    </CInputGroup>
                    <Validation
                      className="mt-0"
                      formik={formik}
                      control="verification_code"
                    />
                    <CRow>
                      <CCol xs="4">
                        <CButton
                          color="primary"
                          type="submit"
                          className="px-2"
                          disabled={!formik.isValid || submitted}
                        >
                          {submitted ? <CSpinner className={"mx-3"} size="sm"/> : 'Verify Now'}
                        </CButton>
                      </CCol>
                      {!submitted ? (
                        <CCol xs="8" className={"text-right"}>
                          {resendReady ? (
                            <CButton
                              color="primary"
                              variant="outline"
                              className="px-3"
                              onClick={sendVerificationCode}
                            >
                              Resend Code
                            </CButton>
                          ) : (
                            <p>
                              Request New Code <strong>{cooldown}</strong> in
                              seconds
                            </p>
                          )}
                        </CCol>
                      ) : (
                        <></>
                      )}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Welcome to the market place.</p>
                    <p>Don't have an account yet?</p>
                    <Link to="/register">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        Become An Entrepreneur!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetCode;
