import { combineReducers } from 'redux';
import AddressReducer from './AddressReducers';
import CategoryReducer from './CategoryReducers';
import EntrepreneurReducer from './EntrepreneurReducers';
import OrderReducer from './OrderReducers';

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const reducer = combineReducers({
  nav: changeState,
  category: CategoryReducer,
  address: AddressReducer,
  entrepreneur: EntrepreneurReducer,
  newOrder: OrderReducer
})

export default reducer;
