import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
let headers = {};
headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

const api = axios.create({
  baseURL: apiUrl,
  headers,
});

api.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        console.log(error);
        reject(error);
      });
    }

    if ([401, 500].includes(error.response.status) && error.response.data && error.response.data.message && error.response.data.message === 'Token has expired') {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // if (history) {
      //     history.push("/auth/login");
      // } else {
      window.location = "/";
      // }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default api;
