
const CategoryReducer = (state = [], action) => {

    switch (action.type) {
        case 'SET_CATEGORY_ITEM':
            return action.payload;

        default:
            return state;
    }
}

export default CategoryReducer;