import React, { useState } from 'react';
import ConfirmEmail from './ConfirmEmail';
import ResetCode from './ResetCode';
import ResetPassword from './ResetPassword';


const ForgotPassword = () => {
  const [step, setStep] = useState("CONFIRM_EMAIL");
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");

  const emailSet = mail => {
    setEmail(mail);
    setStep("CODE_VERIFY");
  };

  const applyCode = code => {
    setResetCode(code);
    setStep("CHANGE_PASSWORD");
  };

  return (
    <>
      {step === "CONFIRM_EMAIL" && <ConfirmEmail emailSet={emailSet} />}
      {step === "CODE_VERIFY" && email && (
        <ResetCode email={email} applyCode={applyCode} />
      )}
      {step === "CHANGE_PASSWORD" && email && resetCode && (
        <ResetPassword email={email} resetCode={resetCode} />
      )}
    </>
  )
}

export default ForgotPassword;
