import { useFormik } from "formik";
import React from 'react';
// import { Button, Col, Container, Form, FormControl, Modal, Row } from "react-bootstrap";
// import { toast } from "react-toastify";
import * as Yup from 'yup';
import CIcon from '@coreui/icons-react';
import * as AuthApi from '../../../services/authApi';
import { CButton, CCol, CForm, CInput, CRow, CCard, CCardBody, CInputGroup, CInputGroupPrepend, CInputGroupText, CLink, CContainer, CCardGroup, } from '@coreui/react';
import cogoToast from 'cogo-toast';
import Validation from "src/helpers/validation";
import { useHistory } from "react-router";

const ResetPassword = (props) => {

  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema:
      Yup.object({
        password: Yup.string().required("Password is required.")
          .min(8, 'Password must contain at least 8 digit.'),
        confirm_password: Yup.string().required("Confirm password is required.").when("password", {
          is: val => (val && val.length > 0 ? true : true),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password didn't matched."
          )
        }),
      }),
    onSubmit: (values, { resetForm }) => {
      values.email = props.email;
      values.reset_token = props.resetCode;
      AuthApi.resetPassword(values).then(
        (res) => {
          if (res.statusCode === 200) {
            cogoToast.success("Password successfully changed!");
            history.push('/login');
          } else {
            cogoToast.error("Something went wront!");
          }
        })
        .catch(error => {
          const err = error.response;
        }
        );
    }
  });



  return (

    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit} method="post" className="login-form" encType="multipart/form-data" >
                    <h1>Reset Password</h1>
                    <p className="text-muted">Create a new password!</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" value={formik.values.password} name="password"
                        onChange={formik.handleChange} placeholder="Password" />
                    </CInputGroup>
                    <Validation formik={formik} control="password" />

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" value={formik.values.confirm_password} name="confirm_password"
                        onChange={formik.handleChange} placeholder="Confirm Password" />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" type="submit" className="px-4">Reset Password</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CLink to="/login">
                          <CButton color="link" className="px-0">Login Page</CButton>
                        </CLink>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Welcome to the market place.</p>
                    <p>Don't have an account yet?</p>
                    <CLink to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Become An Entrepreneur!</CButton>
                    </CLink>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword;
