import * as AddressApi from '../../services/addressApi';

export const setAddressItem = () => {
  return (dispatch) => {
    AddressApi.getAddress().then(
      catData => {
        if (catData.statusCode === 200) {
          dispatch({ type: 'SET_ADDRESS_ITEM', payload: catData.data })
        }
      }
    )
  }
}
