import * as AuthApi from '../../services/authApi';

export const setEntrepreneur = () => {
  return (dispatch) => {
    AuthApi.getEntrepreneurDetail().then(
      res => {
        if (res.statusCode === 200) {
          localStorage.setItem('entrepreneur', JSON.stringify(res.data));
          dispatch({ type: 'SET_ENTREPRENEUR', payload: res.data })
        }
      }
    )
  }
}
