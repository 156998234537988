import * as OrderApi from '../../services/orderApi';

export const setNewOrders = () => {
  return (dispatch) => {
    OrderApi.newOrder().then(
      res => {
        if (res.statusCode === 200) {
          dispatch({ type: 'SET_NEW_ORDER_LIST', payload: res.data })
        }
      }
    )
  }
}

export const setOrderSeen = orderId => {
  return (dispatch) => {
    OrderApi.orderSeen(orderId).then(
      res => {
        if (res.statusCode === 200) {
          dispatch({ type: 'REMOVE_ORDER', payload: orderId })
        }
      }
    )
  }
}
export const REMOVE_ORDER_NOTIFICATION = 'REMOVE_ORDER_NOTIFICATION';

export const removeOrderNotification = orderId => {
  return {
    type: REMOVE_ORDER_NOTIFICATION,
    payload: orderId
  };
};