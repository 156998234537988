import React, { useState } from "react";
import VerifyCode from "./VerifyCode";
import { useHistory } from "react-router";

const AccountVerify = (props) => {
  const location = props.location;
  const history = useHistory();
  if (!location.state) {
    history.push("/login");
  } else {
    const { data } = props.location.state;
    return (
      <>
        <VerifyCode seller={data} />
      </>
    );
  }
  return(
    <><VerifyCode seller={{}} /></>
  )
};

export default AccountVerify;
