
const OrderReducer = (state = [], action) => {

  switch (action.type) {
    case 'SET_NEW_ORDER_LIST':
      return action.payload;

    case 'REMOVE_ORDER':
      const order = state.filter(x => x.id != action.payload);
      return order;

    default:
      return state;
  }
}

export default OrderReducer;
