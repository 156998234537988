import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { connect } from "react-redux";
import * as CategoryAction from "./reducers/actions/CategoryActions";
import * as AddressAction from "./reducers/actions/AddressActions";
import * as AuthAction from "./reducers/actions/AuthAction";
import * as OrderAction from "./reducers/actions/OrderAction";
import ForgotPassword from "./views/reset/login/ForgotPassword";
import AccountVerify from "./views/reset/register/AccountVerify";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/reset/login/Login"));
const Register = React.lazy(() => import("./views/reset/register/Register"));
const Page404 = React.lazy(() => import("./views/reset/page404/Page404"));
const Page500 = React.lazy(() => import("./views/reset/page500/Page500"));

const App = (props) => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      props.categoryItem([]);
      props.addressItem([]);
      props.setEntrepreneur([user]);
      props.setOrderList([]);
    }
  }, []);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/verify-account"
            name="Verify Account"
            render={(props) => <AccountVerify {...props} />}
          />
          {/* <Route exact path="/reset-password/:id/:code" name="Reset Password" render={props => <ResetPassword {...props} />} /> */}
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

const mapDisptachToProps = (dispatch) => {
  return {
    categoryItem: (item) => {
      dispatch(CategoryAction.setCategoryItem(item));
    },
    addressItem: (item) => {
      dispatch(AddressAction.setAddressItem(item));
    },
    setEntrepreneur: (data) => {
      dispatch(AuthAction.setEntrepreneur(data));
    },
    setOrderList: (data) => {
      dispatch(OrderAction.setNewOrders(data));
    },
  };
};

export default connect(null, mapDisptachToProps)(App)
